h1 {
  color:rgb(62, 69, 77);
  font-weight:600;
  margin:60px 10px;
}

h1 span {
  display:block;
}

h1 span a {
  color:rgb(182, 181, 181);
}

h2 {
  color:rgb(62, 69, 77);
  font-weight:600;
  margin:20px 0px 40px 0;
  border-bottom: solid 2px rgb( 242, 135, 5);
  padding-bottom:3px;
}

span.help {
  float:right;
  margin-top:-12px;
  margin-right:10px;
}

span.help button {
  margin-bottom:0;
  font-size:14px;
}

h3 {
  border-bottom: solid 2px rgb( 242, 135, 5);
  padding-bottom:3px;
}

h4 {
  color:rgb(62, 69, 77);
  display:inline-block;
  padding-right:10px;
  font-size:105%;
}

input, textarea {
  font-family:inherit;
  font-size: inherit;
}

div.container form {
  display:block;
  border: 1px solid rgb(228,227,233);
  background-color: white;
  padding:10px 15px;
  border-radius:3px;
  box-shadow: 1px 1px 2px rgb(220, 219, 224);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

form label {
  display: block;
  font-weight:600;
  margin:20px 0 10px 0;
  color:rgb(72,71,90)
}

form label.required::after  {
  color:lightgrey;
  font-size:85%;
  content: " (required)";
}

form label span.requiredLabel {
  color:lightgrey;
  font-size:85%;
}

form p {
  margin:0 0 10px 0;
  padding:0;
  font-size:80%;
  line-height:1.3em;
  color:rgb(129, 129, 129)
}

form input, form select, form textarea {
  border:0;
  border: 1px solid rgb(226, 226, 226);
  color:rgb(129, 129, 129);
  font-size:16px;
  box-shadow: 0px 1px 1px #f0f0f0;
}

form input:focus, form select:focus, form textarea:focus { 
  outline: none !important;
  border-color: rgb(192, 192, 192);
}

form input[type=text] {
  padding:10px;
  width:90%;
}

form input[type=search] {
  padding:10px;
}

form textarea {
  width:90%;
  height:150px;
  padding:10px;
}

form input.resetLength, form select.resetLength {
  width: auto;
}

section#navigation div.links  {
  display:inline-block;
  height: 20px;
}

section#navigation div.links a {
  display:inline-block;
}

section#navigation div.search {
  display:inline-block;
}

section#navigation div.search form input[type=search] {
  
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: brightness(50%);
}

section.footer {
  padding:15px;
  background:white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  min-height:10vh;
  margin: 15px 15px 0 15px;
  border: 1px solid rgb(228,227,233);
  box-shadow: 1px 1px 2px rgb(220, 219, 224);
}

section.footer p {
  text-align: left;
  font-size:0.85em;
}

form input[type=number] {
  padding:10px;
  margin:10px 0; 
  width: 90px;
}

form input[type=date] {
  padding:10px;
  margin:10px 0; 
  width: 90%;
}

form select.shortDate {
  width: 280px;
}

form select.shortDate {
  width: 300px;
}

form select {
  padding:10px 25px 10px 10px;
  margin:10px 0; 
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  background: url(./images/select_arrow.png) no-repeat;
  background-position: right center;
  background-color: #ffffff;
  border: 1px solid rgb(222, 222, 222);
}

button {
  appearance:none;
  -webkit-appearance:none;
  padding:10px;
  margin-bottom:20px;
  border:none;
  background-color:rgb(242,159,5);
  color:#f5f2ee;
  text-shadow: 1px 1px 1px rgb(221, 145, 4);
  font-weight:600;
  border-radius:3px;
  min-width: 80px;
  display:inline-block;
  font-size: 0.8em;
}

button:hover {
  background-color:rgb(207, 137, 7);
}

button.right {
  float:right;
}

button.submit {
  font-size:110%;
  margin-top:20px;
}

.close {
  min-width: auto;
  border-radius:20px;
  padding:7px 10px;
  margin:15px 0 0 0;
}

span.contexthelp {
  padding:3px 8px;
  background-color: rgba( 242, 135, 5, 1);
  color:white;
  font-size:12px;
  border-radius: 20px;
  text-shadow: 1px 1px 1px rgb(221, 145, 4);
  margin-left:10px;
}

form span.form_error {
  display:inline-block;
  padding:5px 5px 10px 0;
  font-weight:600;
  color: rgb(221, 94, 94);
  font-size:90%;
}

form span.form_error::before  {
  content: "";
}

form span.form_error::after  {
  content: "";
}

a {
  color:rgb(242,159,5);
}



p.collectionmeta, p.grademeta  {
  font-size:90%;
  margin-left:10px;
  color:rgb(62, 69, 77);
}

p.collectionmeta span, p.grademeta span {
  background-color:rgb(242, 92, 5);
  padding:5px;
  border-radius:2px;
  color:rgb(253, 250, 249);
  font-size:80%;
}

span.collection_total {
  display: block;
  margin:20px 0 30px 0;
  background: rgb(233, 233, 233);
  padding:15px;
  border-radius:10px;
  font-weight:600;
}

span.collection_total span {
  font-weight:600;
}

span.endnote, p.endnote  {
  font-size:60%;
  color:rgb(136, 139, 143);
  display: inline-block;
  width:70%;
  margin-top:15px;
}

div.notes {
  margin-bottom:35px;
}

div.container {
  margin:15px;
}

div.container ul.choices {
  font-size:16px;
  padding:20px 50px;
  list-style:none;
  background-color:white;
  border-radius: 20px;
}

div.container ul.choices li {
  padding:20px 10px;
}

div.container ul.choices li a {
  text-decoration: none;
}

div.container ul.choices li span {
  padding-right:15px;
}

section#navigation {
  padding:15px 15px 15px 25px;
  border-bottom: 1px solid rgb(228,227,233);
  background: white url(./images/logo.svg) top 10px right 15px no-repeat;
  background-size:150px;
}

section#navigation a {
  color:rgb(62, 69, 77);
  margin: 0 20px 0 0;
  text-decoration:none;
  padding-bottom:5px;
}

section#navigation a:hover {
  border-bottom: 2px solid;
}

table.history {
  width:100%;
  border: 1px solid rgb(228,227,233);
  background-color: white;
  padding:10px 10px 20px 10px;
  border-spacing: 0px;
  margin-bottom:10px;
}

table.history thead th {
  text-align: left;
  padding:10px;
  border-bottom:2px solid rgb( 242, 135, 5);
}

table.history tbody td {
  padding:10px;
  border-bottom: 1px solid rgb(228,227,233);
  color:rgb(129, 129, 129)
}

table.history tbody td span {
  display:block;
  width:100%;
  font-weight:bold;
  font-size:80%;
}

table.history tbody tr.last td {
  padding:10px;
  border-bottom: none;
}

table.history tbody td a.editLink {
  background-color:rgb(242,159,5);
  color:#f5f2ee;
  text-shadow: 1px 1px 1px rgb(221, 145, 4);
  padding: 5px 10px;
  border-radius: 4px;
  text-decoration:none;
}

table.history tbody td strong {
  font-weight:600;
}

div.paging {
  text-align:center;
  padding:15px;
  font-weight:600;
  font-size:90%;
}

div.paging span {
  background:white;
  border-radius: 20px;
  padding:8px 15px;
}

div.history_date_range {
  margin-bottom:40px;
}

div.plot {
  padding:15px;
  background-color: white;
  border: 1px solid rgb(228,227,233);
  color:rgb(129, 129, 129);
  margin-bottom:15px;
}

div.secondary_plot_container {
  overflow-x: auto;
  white-space: nowrap;
}

div.secondary_plot_container div.plot_container {
  width:386px;
  display:inline-block;
  margin-right:20px;
}

div.plot_container h4 {
  display:block;
  width:auto;
  margin-bottom:15px;
  border-bottom: solid 2px rgb(233, 128, 0);
}

div.collectionHistoryEntry h4 {
  display:block;
  width:auto;
  margin-bottom:10px;
}

div.GradingInput h4, div.GradingOutput h4 {
  display:block;
  margin-bottom:15px;
  padding-bottom:5px;
  border-bottom: solid 2px rgb(233, 128, 0);
}

div.graded_by {
  margin-bottom:50px;
}

div.secondary_plot_container div.plot_container div.plot {
  
}

/* div.plot_container:nth-child(2) {
  margin-left:20px;
} */

div.reportcard {
  background:white;
  border: 1px solid rgb(228,227,233);
  padding:15px;
  width:300px;
  display:inline-block;
  margin:0px 10px 10px 0px;
}

div.reportcard h4 {
  padding:0;
  margin:0;
}

div.reportcard h4 a {
  text-decoration:none;
  color:rgb(62, 69, 77);
}

div.reportcard p {
  color:rgb(129, 129, 129);
  font-size:90%;
}

div.GradingInput {

}

div.GradingInput label {

}

div.GradingInput input[type=text] {
  padding:10px;
  margin:10px 0; 
}

div.GradingInput input[type=date] {
  padding:7px 10px 8px 10px;
  margin:10px 0; 
}

div.GradingInput select {
  padding:10px;
  margin:10px 0; 
  width: 100%;
}

div.GradingInput div.items {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 1fr;
  gap: 0px 10px;
  grid-auto-flow: row;
}

div.recievedTools {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  border-bottom: 1px solid rgb(228,227,233);
  padding-top:20px;
  margin-bottom:40px;
}

div.recievedTools div.flex {
  flex-grow: 2;
}

div.recievedTools div.addRowControl {

}

div.recievedTools div.totals {
  margin-right:30px;
  margin-top:5px;
}

div.recievedTools div.totals h3 {
  display:inline;
  padding-right:10px;
}

div.gradingDates {
  display: flex;
  justify-content: flex-start;
  margin-top:10px;
  margin-bottom:20px;
}

div.gradingDates div.item:first-of-type {
  margin-right:20px;
  padding-right: 20px;
  padding-bottom:10px;
}

div.gradingDates input[type=date], div.gradingDates input[type=time]  {
  padding:10px;
  margin:10px 0; 
}

div.gradingDates input[type=date] {
  margin-right:10px;
}

div.GradingOutput div.items {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-auto-rows: 1fr;
  gap: 0px 10px;
  grid-auto-flow: row;
  width:90%;
}

div.GradingOutput select {
  padding:10px;
  margin:10px 0; 
  width: 100px;;
}

div.GradingOutput input[type=text] {
  margin-top:10px;
}

div.GradingOutput input[type=date] {
  padding-top:7px;
  padding-bottom:8px;
}

div.outputTools, div.transferTools, div.saleTools {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  border-bottom: 1px solid rgb(228,227,233);
  padding-top:20px;
  margin-bottom:40px;
}

div.outputTools div.flex, div.transferTools div.flex, div.saleTools  div.flex {
  flex-grow: 2;
}

div.outputTools div.totals, div.transferTools div.totals, div.saleTools  div.totals {
  margin-right:30px;
  margin-top:5px;
}

div.outputTools div.totals h3, div.transferTools div.totals h3, div.saleTools  div.totals h3 {
  display:inline;
  padding-right:10px;
}

div.TransferContent div.items {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 1fr;
  gap: 0px 10px;
  grid-auto-flow: row;
}

div.SaleContent div.items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 1fr;
  gap: 0px 10px;
  grid-auto-flow: row;
}

div.submitFormButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding-top:20px;
  margin-bottom:40px;
}

div.submitFormButtons div.flex {
  flex-grow: 2;
}

div.submitFormButtons div.cancel {
  margin-right:10px;
}

div.submitFormButtons div.cancel button {
  background-color:grey;
  text-shadow: 1px 1px 1px rgb(151, 151, 151);
}

div.inputWithUnit {
  display: flex;
}

div.inputWithUnit div.input {
  margin-right:10px;
}

.highlight {
  border-color:red;
}

div.TransferContent input[type=text] {
  padding:10px;
  margin:10px 0; 
}

div.collectionHistoryEntry div.incoming, div.gradeHistoryEntry div.incoming, div.gradeHistoryEntry div.outgoing {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-flow: row;
  row-gap: 1ch;
  column-gap: 0px;
}

div.collectionHistoryEntry div.incoming {
  grid-template-columns: repeat(12, 1fr);
  margin-top:15px;
  font-size:80%;
}

div.collectionHistoryEntry div.incoming div {
  padding:5px;
}

div.gradeHistoryEntry div.outgoing {
  grid-template-columns: repeat(6, 1fr);
}


div.collectionHistoryEntry div.incoming div.header, div.gradeHistoryEntry div.incoming div.header, div.gradeHistoryEntry div.outgoing div.header, div.transferHistoryEntry div.incoming div.header {
  font-weight: 600;
  border-bottom: 2px solid rgb(221, 145, 4);
  padding:10px 5px;
}

div.collectionHistoryEntry div.incoming div.footer {
  font-weight: 600;
  border-top: 2px solid rgb(221, 145, 4);
  border-bottom: 2px solid rgb(221, 145, 4);
  padding:5px;
}

div.collectionTotal {
  padding:10px 5px;
  font-size:90%;
  border-bottom:rgb(221, 145, 4) 2px solid;
  margin-bottom:15px;
}

div.collectionTotal span {
  font-weight:600;
}

div.totalRow {
  border-top:2px solid rgb(221, 145, 4);
  padding-top:12px;
}

div.total {
  font-weight:600;
}

div.totalValue {
  background:rgb(221, 145, 4);
  margin-left:-10px;
  padding-left:10px;
  color:white;
  font-weight:600;
}

div.gradeHistoryEntry p.noMatch {
  padding:20px 0;
  line-height:1.3em
}

div.gradeHistoryEntry ul.gradingHistoryMeta {
  list-style: none;
  margin:0 0 40px 0;
  padding:0;
}

div.gradeHistoryEntry ul.gradingHistoryMeta li {
  line-height:1.5em;
}

div.gradeHistoryEntry ul.gradingHistoryMeta li span {
  font-weight: 600;
  display: inline-block;
  width:150px;
}

div.transferHistoryEntry div.incoming {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
  row-gap: 1ch;
  column-gap: 0px;
}

div.transferHistoryEntry ul.transferHistoryMeta {
  list-style: none;
  margin:0 0 40px 0;
  padding:0;
}

div.transferHistoryEntry  ul.transferHistoryMeta li {
  line-height:1.5em;
}

div.transferHistoryEntry  ul.transferHistoryMeta li span {
  font-weight: 600;
  display: inline-block;
  width:150px;
}

div.saleHistoryEntry div.sold {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
  row-gap: 1ch;
  column-gap: 0px;
}

div.saleHistoryEntry ul.saleHistoryMeta {
  list-style: none;
  margin:0 0 40px 0;
  padding:0;
}

div.saleHistoryEntry  ul.saleHistoryMeta li {
  line-height:1.5em;
}

div.saleHistoryEntry  ul.saleHistoryMeta li span {
  font-weight: 600;
  display: inline-block;
  width:150px;
}

div.gradingBalance, div.gradingBalanceError {
  padding:10px 28px 10px 10px;
  font-weight: 600;
  text-align:right;
  border:2px solid rgb(242,159,5);
  background: rgba(242, 159, 5, 0.089);
  border-radius:5px;
  margin-top:15px;
}

div.gradingBalanceError {
  border:2px solid rgb(242, 52, 5);
  background: rgba(242, 52, 5, 0.089);
}

div.gradingBalance span, div.gradingBalanceError span {
  font-weight:normal;
  display:block;
  margin-top:10px;
}

span.balancewarning {
  display: block;
  text-align:left;
  font-size:80%;
  color:rgb(99, 103, 107);
}

.graded_by {
  margin-bottom:15px;
}


.icon-right {
  float:right;
  color:rgb(242,159,5);
  text-align:right;
}

div.collectionHistoryEntry {
  overflow-x: auto;
  white-space: nowrap;
}

table {
  border-collapse: collapse;
  width:100%;
  font-size:14px;
  margin:0 0 60px 0;
}

table tr {

}

table tr th {
  background: rgb(242,159,5);
  padding:10px 5px;
  text-align:left;
  border-left: solid 2px rgb(242,159,5);
  color:white;
}

table tr td {
  text-align:left;
  padding:10px 5px;
  border-left: solid 2px rgb(242,159,5);
}

table tr.totals td {
  text-align:left;
  padding:10px 5px;
  border-top: solid 2px rgb(242,159,5);
  border-left:none;
  background-color: white;
  font-weight:600;
}

table tr.totals_balance td.white {
  background-color:white;
  color:black;
  border-right:none;
}  

table tr td:last-child {
  border-right: 2px solid rgb(242,159,5);
}

table tr.totals td:first-child {
  border-left: 2px solid white;
}

table tr.totals td:last-child {
  border-right: 2px solid white;
}

table tr th:last-child {
  border-right: 2px solid rgb(242,159,5);
}

table tr:last-child.nobottom td {
  border-bottom: none;
}

table tr:last-child td {
  border-bottom: 2px solid rgb(242,159,5);
}

table  tr:nth-child(even) { background: #ffffff; }
table  tr:nth-child(odd) { background: #f0f0f0; }

button.exportcsv {
  margin-left:15px;
}

div.gradeHistoryEntry table tr td {
  width:100px;
}

div.recallreport {
  
}

div.recallreport table tr.productInfoTableTopRow td {
  
}

div.recallreport table tr.productInfoTableTopRow:first-child td:first-child {
  border-top-left-radius: 8px;
}

div.recallreport table tr.productInfoTableTopRow:first-child td:last-child {
  border-top-right-radius: 8px;
}

div.recallreport table tr:last-child td {
  border-bottom: none;
}

div.recallreport table tr.productInfoTableRow td, div.recallreport table tr.productInfoTableLastRow td {
  border-right: none;
  border-left: none;
  background-color:#f3f1f1;
}

div.recallreport table tr.productInfoTableRow td span {
  font-weight:600;
}

div.recallreport table tr.productInfoTableRow:nth-child(even) {background: none; }
div.recallreport table tr.productInfoTableRow:nth-child(odd) {background: none; }

div.recallreport table tr.productInfoTableLastRow:nth-child(even) {background: none; }
div.recallreport table tr.productInfoTableLastRow:nth-child(odd) {background: none; }

div.recallreport table tr.productInfoTableLastRow:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

div.recallreport table tr.productInfoTableLastRow:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

div.recallreport table tr:nth-last-child(2) td {
  border-bottom: 2px solid rgb(242,159,5);
}

div.stat_board {
  background-color:white;
  padding:20px 20px 5px 20px;
  border-radius: 20px;
}

div.start_stat_container {
  display:inline-block;
  background-color:white;
  border-radius: 10px;
  padding:15px 15px 5px 15px;
  margin:0 20px 20px 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  min-width:200px;
  min-height:150px;
}

div.start_stat_container h3 {
  border:none;
  margin:0 0 10px 0;
  padding:0;
}

div.start_stat_container p.start_stat {
  font-weight: 700;
  font-size:3em;
  margin:0;
  padding:0;
}

div.start_stat_container p.start_stat span {
  display:block;
  font-weight: 500;
  font-size:0.2em;
  margin:0;
  padding:0;
  color: rgb(126, 126, 126);
}

div.start_stat_container a {
  text-decoration: none;
}

div.double {
  min-width:450px;
}

div.file_utils {
  background-color:white;
  padding:15px;
  border-radius:15px;
  margin-top:15px;
}

div.file_utils h3 {
  margin:0 0 15px 0;
  padding:0;
  border:none;
}

div.file_utils h4 {
  margin:0 0 10px 0;
  padding:0;
  font-size:0.9em;
  display:block;
  font-weight:600;
}

div.file_utils p {
  font-size:0.9em;
  margin-top:5px;
}

.upload_file_input::-webkit-file-upload-button {
  visibility: hidden;
}

.upload_file_input::before {
  content: 'Upload';
  display: inline-block;
  background: rgb(242,159,5);
  border-radius: 3px;
  padding:10px 5px;
  border:none;
  background-color:rgb(242,159,5);
  color:#f5f2ee;
  text-shadow: 1px 1px 1px rgb(221, 145, 4);
  font-weight:600;
  border-radius:3px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 0.8em;
  width:70px;
  text-align: center;
}
.upload_file_input:hover::before {
  background-color:rgb(207, 137, 7);
}
.upload_file_input:active::before {
  background-color:rgb(207, 137, 7);
}

div.not-found {
  background-color:white;
  border-radius: 5px;
  padding:25px;
}

div.content {
  background-color:white;
  padding:15px;
  border-radius:15px;
  max-width: 800px;
  min-width:400px;
}

div.help-sections ul {
  list-style: none;
  padding:0;
  margin:0;
}

div.help ul {
  margin-bottom:25px;
}

div.help ul li {
  line-height:1.5em;
}

div.help-sections ul li {
  line-height:1.5em;
}

a.button {
  padding:10px;
  margin-bottom:20px;
  border:none;
  background-color:rgb(242,159,5);
  color:#f5f2ee;
  text-shadow: 1px 1px 1px rgb(221, 145, 4);
  font-weight:600;
  border-radius:3px;
  min-width: 50px;
  text-align: center;
  display:inline-block;
  font-size: 14px;
  text-decoration:none;
}

a.button:hover {
  background-color:rgb(207, 137, 7);
}






@media (min-width: 320px) and (max-width: 480px) {
  div.start_stat_container {
    display:block;
    background-color:white;
    border-radius: 10px;
    padding:15px 15px 5px 15px;
    margin:0 0 20px 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .upload_file_input::before {
    content: 'Upload';
    display: block;
    background: rgb(242,159,5);
    border-radius: 3px;
    padding:10px 6px;
    border:none;
    background-color:rgb(242,159,5);
    color:#f5f2ee;
    text-shadow: 1px 1px 1px rgb(221, 145, 4);
    font-weight:600;
    border-radius:3px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 0.8em;
    text-align: center;
  }

  .links {
    margin-top:35px;
  }
}